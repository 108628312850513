<template>
	<div class="CommodityList">
		<w-navTab titleText="商品购物车"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="CommodityList-body">
			<div class="container" v-for="(item,index) in list">
				<div class="container-body">
					<van-swipe-cell disabled v-for="(vo,voIndex) in item.products">
						<div class="container-select">
							<van-checkbox v-model="vo.checked" @click="onCheckbox(index,voIndex)"></van-checkbox>
						</div>
						<div class="container-picture"
							@click="$router.push({ name: 'CommodityDetail', params: { id: vo.product_id }})">
							<img :src="vo.thumb" alt="">
						</div>
						<div class="container-right-box">
							<div class="container-right-box-title">
								<div class="title-text">
									{{vo.title}}
								</div>
								<div class="button-delete" @click="onClickDelete(vo.cart_id,index,voIndex)">
									删除
								</div>
							</div>
							<div class="price-box">
								<div class="right-text" v-if="vo.integral != 0">爱心积分{{vo.integral}}</div>
								<div class="right-number" v-if="vo.integral != 0">抵{{vo.discount}}元</div>
							</div>
							<div class="right-box-bottom">
								<div class="right-box-bottom-left">
									<span>￥</span>{{vo.price}}
								</div>
								<van-stepper v-model="vo.num" min="1" theme="round" button-size="22" disable-input
									@plus="onPlus(vo,index,voIndex)" @minus="onMinus(vo,index,voIndex)" />
							</div>
						</div>
						<!-- <template #right>
							<van-button square type="danger" text="删除"/>
						</template> -->
					</van-swipe-cell>
				</div>
				<div class="container-footer">
					<div class="container-footer-left">
						<van-checkbox v-model="item.checked" @click="onSelectAll(index)">全选</van-checkbox>
					</div>
					<div class="container-footer-right">
						<div class="container-footer-right-text">
							<div class="footer-right-text-title">
								<span>￥</span>{{(parseInt(countPrice(index)*1000 - countAmount(index)*1000)/1000).toFixed(2)}}
							</div>
							<div class="footer-right-text-rebate">
								<div class="rebate-left">
									{{countIntegral(index)}}<span>爱心积分抵扣</span>
								</div>
								<div class="rebate-right">
									<span>￥</span>{{(parseInt(countAmount(index)*1000)/1000).toFixed(2)}}
								</div>
							</div>
						</div>
						<div class="container-footer-right-btn" @click="toShopping(index)">去支付</div>
					</div>
				</div>
			</div>
			<van-empty v-if="list.length == 0" class="custom-image"
				image="https://img01.yzcdn.cn/vant/custom-empty-image.png" description="购物车是空的" />
		</div>
	</div>
</template>

<script>
	import VantVendor from '@/vendor/vant';
	import VantList from '@/components/VantList';
	import Commodity from '@/api/commodity';
	import {
		Checkbox
	} from 'vant';
	import LoadingPage from '@/components/LoadingPage';
	import math from '@/vendor/math.js'
	export default {
		name: "CommodityList",
		data() {
			return {
				list: [],
				price: '',
				loadingPage: true,
			}
		},
		computed: {
			shopping() {
				return this.$store.state.shopping.shopping
			}
		},
		created() {
			this.$store.commit('keep/removeKeep', 'CommodityShopping');
			this.onLoad()
		},
		methods: {
			// 总价
			countPrice(index) {
				let price = 0
				// if(this.list[index].products)
				this.list[index].products.forEach(item => {
					if (item.checked == true) {
						price += item.price * item.num
					}
				})
				return price
			},
			// 总爱心
			countIntegral(index) {
				let integral = 0
				this.list[index].products.forEach(item => {
					if (item.checked == true) {
						integral += item.integral * item.num
					}
				})
				return integral
			},
			// 总折扣
			countAmount(index) {
				let discount = 0
				this.list[index].products.forEach(item => {
					if (item.checked == true) {
						discount += item.discount * item.num
					}
				})
				return discount
			},
			// 购物车列表
			onLoad() {
				Commodity.productCartIndex()
					.then(result => {
						result.data.list.forEach(item => {
							item.checked = false
							item.products.forEach(vo => {
								vo.checked = false
							})
						})
						this.loadingPage = false
						this.list = result.data.list
						let list = []
						this.renewShopp()
					}, error => {
						VantVendor.Toast.fail(error.msg);
					})
			},
			// 更新缓存的购物车
			renewShopp() {
				let shoppList = {}
				this.list.forEach(item => {
					item.products.forEach(vo => {
						shoppList[vo.product_id] = vo.num
					})
				})
				this.$store.commit('shopping/setShopping', shoppList);
			},
			// 商品++
			onPlus(vo, index, voIndex) {
				let params = {
					product_id: vo.product_id,
				}
				Commodity.productCartIncNum(params)
					.then(result => {
						console.log('添加购物车成功');
						this.renewShopp()
					}, error => {
						vo.num--;
						VantVendor.Toast.fail(error.msg);
					})
			},
			// 商品--
			onMinus(vo) {
				let params = {
					product_id: vo.product_id,
				}
				Commodity.productCartDecNum(params)
					.then(result => {
						console.log('减少购物车成功');
						this.renewShopp()
					}, error => {
						vo.num++;
						VantVendor.Toast.fail(error.msg);
					})
			},
			toDetail() {
				event.stopPropagation();
				this.$router.push({
					name: 'CommodityDetail'
				})
			},
			onClickDelete(id, index, voIndex) {
				VantVendor.Dialog.confirm({
						title: '删除提示',
						message: '您确认删除吗？',
					})
					.then(() => {
						Commodity.productCartDelete({
								cart_id: id
							})
							.then(result => {
								this.list[index].products.splice(voIndex, 1)
								if (this.list[index].products == '') {
									this.list.splice(index, 1)
								}
								VantVendor.Toast.success('删除成功')

								this.renewShopp()
							}, error => {
								VantVendor.Toast.fail(error.msg);
							})
					})

			},
			onCheckbox(index, voIndex) {
				if (this.list[index].products.findIndex(item => item.checked == false) == -1) {
					this.list[index].checked = true
				} else {
					this.list[index].checked = false
				}
			},
			onSelectAll(index) {
				this.list[index].products.forEach(item => {
					item.checked = this.list[index].checked
				})
			},
			// 去支付
			toShopping(index) {

				let list = []
				this.list[index].products.forEach(item => {
					if (item.checked == true) {
						list.push(item.cart_id)
					}
				})
				let cartId = ''
				cartId = list.join(',')
				if (cartId == '') {
					return VantVendor.Toast('请选择商品！')
				}
				this.$router.push({
					name: 'CommodityShopping',
					params: {
						id: cartId
					}
				})
			},
		},
		components: {
			VantList,
			LoadingPage
		}
	}
</script>

<style lang="less" scoped>
	.CommodityList {

		.CommodityList-body {
			padding: 8px 10px;
			padding-bottom: 20px;
			box-sizing: border-box;

			.container:last-child {
				margin-bottom: 0;
			}

			/deep/.van-stepper--round .van-stepper__minus {
				color: #3377ff;
				background-color: #fff;
				border: 1px solid #3377ff;
				opacity: 1;
			}

			/deep/.van-stepper--round .van-stepper__plus {
				background: #3377ff;
			}

			.container {
				background: #FFF;
				border-radius: 8px;
				margin-bottom: 16px;
				box-sizing: border-box;
				overflow: hidden;

				.container-body {
					padding: 0 16px;
					box-sizing: border-box;

					.van-swipe-cell:last-child {
						border: 0;
					}

					.van-swipe-cell {
						background: #FFF;
						border-bottom: 1px solid #F2F2F2;
						box-sizing: border-box;

						/deep/.van-swipe-cell__right {
							height: 100%;
							line-height: 6.5;

							.van-button--danger {
								color: #ee0a24 !important;
								background: #FFF !important;
								border: 0;
							}
						}

						/deep/.van-swipe-cell__wrapper {
							display: flex;
							align-items: center;
							padding: 12px 0;
							box-sizing: border-box;

							.container-select {
								padding-right: 16px;
							}

							.container-picture {
								width: 80px;
								height: 80px;
								min-width: 80px;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
									border-radius: 6px;
								}
							}

							.container-right-box {
								flex: 1;
								padding-left: 10px;
								box-sizing: border-box;

								.container-right-box-title {
									display: flex;
									justify-content: space-between;

									.title-text {
										width: 150px;
										display: block;
										font-size: 16px;
										line-height: 22px;
										color: #000;
										white-space: nowrap;
										overflow: hidden;
										text-overflow: ellipsis;
									}

									.button-delete {
										font-size: 16px;
										line-height: 22px;
										color: #ee0a24;
									}
								}

								.price-box {
									display: flex;
									align-items: center;
									margin-top: 6px;
									height: 18px;
									min-height: 18px;
									box-sizing: border-box;

									.right-text {
										font-size: 10px;
										line-height: 16px;
										color: #E95749;
										padding: 0 3px 0 3px;
										border: 1px solid #E95749;
										border-right: 1px dashed #E95749;
										background: rgba(233, 87, 73, .1);
										border-top-left-radius: 2px;
										border-bottom-left-radius: 2px;
										box-sizing: border-box;
									}

									.right-number {
										padding: 0 3px;
										font-size: 10px;
										line-height: 16px;
										border: 1px solid #E95749;
										border-left: 0;
										color: #E95749;
										border-top-right-radius: 2px;
										border-bottom-right-radius: 2px;
										box-sizing: border-box;
									}
								}

								.right-box-bottom {
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding-top: 12px;
									box-sizing: border-box;

									.right-box-bottom-left {
										font-size: 22px;
										line-height: 22px;
										color: #F9615D;

										span {
											font-size: 16px;
											line-height: 16px;
											color: #F9615D;
										}
									}
								}
							}
						}
					}
				}


				.container-footer {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					background: rgba(51, 119, 255, 0.05);
					padding: 6px 0 8px;
					box-sizing: border-box;

					.container-footer-left {
						align-items: center;
						margin-left: 16px;
					}

					.container-footer-right {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.container-footer-right-text {
							.footer-right-text-title {
								font-size: 28px;
								color: #f9615d;
								text-align: right;

								span {
									font-size: 16px;
								}
							}

							.footer-right-text-rebate {
								display: flex;
								justify-content: flex-end;
								align-items: center;

								.rebate-left {
									font-size: 11px;
									color: #f43e3d;

									span {
										font-size: 11px;
										color: #777;
									}
								}

								.rebate-right {
									font-size: 16px;
									color: #f43e3d;
									text-align: right;

									span {
										font-size: 10px;
									}
								}
							}
						}

						.container-footer-right-btn {
							font-size: 16px;
							line-height: 22px;
							color: #fff;
							padding: 9px 22px;
							background: #3377ff;
							border-radius: 100px;
							margin: 0 10px;
						}
					}
				}
			}
		}

	}
</style>